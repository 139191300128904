/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Tenders  Details  
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Tender Details Style Start ------- */
.body-conetnt-header {
  .tender_details {
    flex-direction: row;
    justify-content: space-between;
    // gap: 400px;

    span {
      font-size: $text-13;
      font-weight: $text-500;
      text-transform: uppercase;
      line-height: $leading-22;
      color: $mutedone;
    }

    .tender_info {
      gap: 130px;

      p {
        white-space: nowrap;
        font-size: $text-18;
        margin-top: 12px;
        font-weight: $text-600;
        line-height: $leading-25;
      }
    }

    .bidtender_info {
      gap: 130px;

      p {
        white-space: nowrap;
        font-size: $text-18;
        margin-top: 12px;
        font-weight: $text-600;
        line-height: $leading-25;
      }
    }

    .bids_texts {
      p {
        white-space: nowrap;
        font-size: $text-18;
        margin-top: 12px;
        // font-weight: $text-700;
        line-height: $leading-25;
      }
    }

    .bidsboxlist {
      display: flex;
      align-items: flex-start;
      gap: 140px;

      .yourbid_texts {
        p {
          font-weight: $text-700 !important;
        }
      }
    }

    .total_value {
      p {
        margin-top: 12px;
        font-size: $text-20;
        font-weight: $text-600;
        line-height: $leading-20;
        margin-top: 12px;
      }

      .font-bold {
        font-weight: $text-700 !important;
      }
    }
  }
}

.custom-table {
  [aria-hidden="true"] {
    display: none;
  }

  .custom-box {
    // border: 1px solid $mutedseven;
    height: 50px;
    max-width: 154px;
    border-radius: $rounded-05 !important;

    [data-slot="input-wrapper"] {
      border-radius: $rounded-05 !important;
    }
  }
}

.tender-foot {
  border-top: 1px solid $graysix;
  background-color: $white;
  z-index: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  button {
    border-radius: $rounded-05;
    text-transform: uppercase;
    font-size: $text-14;
    padding: 12px 40px;
    font-weight: $text-500;

    &:hover {
      opacity: 100%;
    }
  }

  .cancletender_btn {
    background-color: transparent;

    &:hover {
      background-color: $darkred;
      color: $white;
    }
  }

  .savetender_btn {
    background-color: $dark-blue;
    font-weight: $text-500;
    color: $white;
  }
}

.search-input {
  [data-slot="input-wrapper"] {
    border-radius: $rounded-04 !important;
  }
}


.inputs_box {
  [data-slot="main-wrapper"]{
    display: flex;
    align-items: center;
  }
}

/* ------- Tender Details Style End ------- */

/* ------- Media Query Start ------- */

@media screen and (max-width: 1730px) {
  .body-conetnt-header {
    .tender_info {
      gap: 90px !important;
    }

    .bidtender_info {
      grid-area: 90px !important;
    }
  }

  .bidsboxlist {
    gap: 70px !important;

  }
}

@media screen and (max-width: 1535px) {
  .body-conetnt-header {
    .tender_details {
      flex-direction: column;
      gap: 30px;
    }

    .tender_info {
      gap: 240px !important;
    }

    .bidtender_info {
      gap: 150px !important;
    }

    .bidsboxlist {
      gap: 150px !important;
    }
  }
}

@media screen and (max-width: 1400px) {
  .body-conetnt-header {
    .tender_info {
      gap: 30px !important;
      width: 100%;
    }

    .bidtender_info {
      gap: 30px !important;
      flex-direction: column;
    }

    .bidsboxlist {
      gap: 30px !important;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 1023px) {
  .body-conetnt-header {
    .tender_details {
      span {
        font-size: $text-12;
      }

      .tender_info {
        p {
          font-size: $text-16 !important;
          line-height: $leading-20;
          margin-top: 10px;
        }
      }

      .bidtender_info {
        p {
          font-size: $text-16 !important;
          line-height: $leading-20;
          margin-top: 10px;
        }
      }

      .total_value {
        p {
          font-size: $text-18;
          line-height: $leading-18;
          margin-top: 10px;
        }
      }
    }
  }

  .bids_texts {
    p {
      font-size: $text-16 !important;
      line-height: $leading-20;
      margin-top: 10px;
    }
  }

  .tender-foot {
    left: 0;
  }
}

@media screen and (max-width: 767px) {
  .body-conetnt-header {
    .tender_info {
      flex-direction: column;
      justify-content: center;
    }
  }

  .tender-foot {
    button {
      padding: 12px 36px;
    }
  }
}

/* ------- Media Query End ------- */