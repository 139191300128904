/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Open-Tenders   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Open-Tenders Style Start ------- */
.drop-box {
  z-index: 10 !important;

  li {
    &:hover {
      background-color: transparent !important;
    }
  }
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  display: flex;
  align-items: center;
  z-index: 0 !important;
}

.loader {
  border: 6px solid $dark-blue;
  border-top: 6px solid #fff;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  animation: spin 1s linear infinite;
}

.small-loader {
  border: 3px solid #004f9c;
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.bid_btn {
  button {
    border-radius: $rounded-05 !important;
  }
}

/* ------- Open-Tenders Style End ------- */

/* ------- Media Query Start ------- */

@media screen and (max-width: 1023px) {
  .body-conetnt-header {
    .opentender-tabs {
      padding: 0 20px 0 20px;
    }
  }
}

/* ------- Media Query End ------- */
