/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Sidebar   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../../variables.scss";

/* ------- Sidebar Style Start ------- */
.sidebar {
    width: 260px;
    padding: 42px 0 35px 0;
    position: fixed;
    top: 86px;
    bottom: 0;
    left: 0;
    transition: all 0.1s;
    // z-index: 1000001 !important;
    z-index: 2 !important;

    // .ActiveColor {
    //
    // }

    .active-link {
        opacity: 100% !important;
        background-color: $blue;
        color: $white;
        opacity: 100%;
        font-weight: $text-600;

        a {
            color: $white;
            opacity: 100%;
            font-weight: $text-600;
        }

        p {
            color: $white;
            opacity: 100%;
            font-weight: $text-600;
        }

        &:hover {
            color: $white;
            opacity: 100%;
        }
    }

    .custom-hov {
        &:hover {
            opacity: 100% !important;
            background-color: $blue;
            color: $white;
            opacity: 100%;

            a,
            p,
            &:hover {
                color: $white;
                opacity: 100%;
            }
        }
    }

    .nav-list {
        display: flex;
        flex-direction: column;
        gap: 2px;

        li {
            padding: 16px 0 16px 64px;
            font-weight: $text-500;
            line-height: $leading-19;
        }

        [data-focus="true"] {
            background-color: $blue;
            font-weight: $text-700;
        }
    }
}
/* ------- Sidebar Style End ------- */

/* ------- Media Query Start ------- */
@media screen and (max-width: 1023px) {
    .sidebar {
        position: fixed;
        left: -260px !important;
        bottom: 0;
        top: 84px !important;
        z-index: 20 !important;
        transition: all 0.1s;
    }
}
/* ------- Media Query End ------- */
