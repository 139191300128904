/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Tailwind Files
  2. Variable File
  3. Coman 
  4. Base
  5. Media Query
 ------------------------*/

//  Tailwind Style File
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

// Variable Style File
@import "./variables.scss";

/* ------- Coman Style Start ------- */
* {
    box-sizing: border-box;
    padding: 0;
}

::-webkit-scrollbar {
    width: 7px;
}

// ::-webkit-scrollbar-track {
//     border: 1px solid $graysix;
//     border-radius: 50px;
// }

::-webkit-scrollbar-thumb {
    background: $graysix;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: $graysix;
}

body {
    font-family: "Noto Sans", sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    background-color: $white;

    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0;
    }

    button {
        outline: none;

        &:focus,
        &:focus-visible,
        &:focus-within,
        &:active,
        &:hover {
            outline: none !important;
            box-shadow: none !important;
        }
    }
}

/* ------- Coman Style End ------- */

/* ------- Helper Style Start ------- */
.admin-content {
    // height: 100vh;
    // margin-right: 22px;
    display: flex;
    flex-direction: column;

    .main-content {
        width: 100%;
        height: 100%;
        margin-right: 16px;
        height: calc(100vh - 86px);
        position: relative !important;
        overflow: auto;
    }

    .body-content {
        width: calc(100% - 260px);
    }
}

.body-conetnt-header {
    padding: 21px 30px;

    .chip {
        border-radius: $leading-10;
        height: 26px;
        min-width: 90px;
        text-align: center;

        span {
            font-size: $text-14;
            line-height: $leading-20;
            font-weight: $text-600 !important;
        }
    }

    .add_btn {
        font-size: $text-15;
        background-color: $dark-blue;
    }
}

.body-tabs-content {
    [data-slot="base"] {
        display: none;
    }

    [data-focus="false"] {
        [data-slot="base"] {
            display: block;
        }
    }
}

/* ------- Helper Style End ------- */

/* ------- Base Style Start ------- */
.custom-table {
    overflow-x: auto !important;

    .bg-content1 {
        padding: 0;
    }

    thead {
        // border-top: 2px solid $graytwo;
        background-color: #fff;
        border-bottom: 2px solid $graytwo;
        position: sticky;
        top: 0 !important;
        box-shadow: 0 0px 2px 0px $graytwo !important;
        z-index: 9;

        &:nth-last-child(1) {
            display: none !important;
        }

        tr {
            box-shadow: none !important;

            th {
                background-color: $white !important;

                text-transform: uppercase;
                font-size: $text-12;
                line-height: $leading-16;
                color: $muted;
                padding: 16px 24px;
                white-space: nowrap;

                &:nth-last-child(1) {
                    padding-right: 30px;
                }

                &:nth-child(1) {
                    padding-left: 30px;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: $text-16;
                font-weight: $text-500;
                line-height: $leading-25;
                color: $dark;
                padding: 0px 24px;
                align-items: center !important;
                height: 64px;
                white-space: nowrap;
                border-bottom: 1px solid $graysix;

                &:before {
                    display: none !important;
                }

                &:nth-last-child(1) {
                    padding-right: 30px !important;
                    z-index: 0 !important;
                    // margin-right: 14px !important;
                    // display: grid !important;
                }

                &:nth-child(1) {
                    padding-left: 30px !important;
                    z-index: 0 !important;
                    // margin-left: 14px !important;
                    // display: grid;
                }
            }
        }
    }

    [aria-hidden="true"] {
        display: none;
    }
}

.tablefix {
    padding-bottom: 65px;
}

.paginations {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white;
    z-index: 1;
    border-top: 2px solid $grayone;
}

.custom_btns {
    button {
        border-radius: $rounded-05 !important;
    }
}

.back_arrow {
    height: 36px;
    width: 36px;
    border-radius: $rounded-circle;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $mutedeight;
    }
}

.cancle_arrow {
    height: 36px;
    width: 36px;
    border-radius: $rounded-circle;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $light-red;

        svg {
            fill: $redtwo;
        }
    }
}

.nextui-pagination-item {
    background-color: #004f9c !important; /* For the background color */
    border-color: #004f9c !important; /* For the border color */
}

.nextui-pagination-item:hover {
    opacity: 0.8; /* Adjust hover state as needed */
}

.nextui-pagination-item-active {
    background-color: #004f9c !important; /* For active page */
    color: white !important; /* Text color for active page */
}

/* ------- Base Style End ------- */

/* ------- Meddia Query Start ------- */
@media screen and (max-width: 1023px) {
    .admin-content {
        margin-right: 0px;

        .body-content {
            width: calc(100% - 0px) !important;
        }
    }

    .body-conetnt-header {
        padding: 21px 20px;
    }

    .responsive-sidebar {
        .sidebar {
            left: 0 !important;
            transition: all 0.1s;
            z-index: 99 !important;
        }
    }
}

@media screen and (max-width: 991px) {
    .custom-table {
        tbody {
            tr {
                td {
                    font-size: $text-15;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .body-conetnt-header {
        .add_btn {
            font-size: $text-14;
        }
    }

    .custom-table {
        thead {
            position: static !important;
            z-index: 0 !important;
        }

        tbody {
            tr {
                td {
                    font-size: $text-14;
                }
            }
        }
    }
}

/* ------- Meddia Query End ------- */
