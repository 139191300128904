/* ------------------------------------------------
  Project:- K-Star-Export Website
  Author:-  DCPL Technology Private Limited
------------------------------------------------ */

/* ------------------------
    All Style List

  1. Variable Files
  2. Open-Tenders   
  3. Media Query
 ------------------------*/

//  Variable Style File
@import "../variables.scss";

/* ------- Open-Tenders Style Start ------- */
.drop-box {
    z-index: 10 !important;
    li {
        &:hover {
            background-color: transparent !important;
        }
    }
}
/* ------- Open-Tenders Style End ------- */

/* ------- Media Query Start ------- */

@media screen and (max-width: 1023px) {
    .body-conetnt-header {
        .opentender-tabs {
            padding: 0 20px 0 20px;
        }
    }
}
/* ------- Media Query End ------- */
